import { TypeOfLoanEnum } from 'services/types';

interface AppTitleProp {
  [key: string]: string;
}
export const AppRoutes = {
  // replaceParams(route: string, params: Record<string, string> = {}) {
  //   let finalRoute = route;
  //   Object.keys(params).forEach((key) => {
  //     finalRoute = finalRoute.replace(`:${key}`, params[key]);
  //   });
  //   return finalRoute;
  // },
  // thankScreen: '/thank/:appId',
  thankScreenClient: '/thank-client',
  confirmOtp: '/cotp/:appId',
  customerVerify: '/cvsi/:appId',
  customerDocuments: '/customer-documents/:appId',
  customerRequestDocuments: '/customer-request-documents/:appId',
  confirmOtpParams: (appId: string | number) => `/cotp/${appId}`,
  thankScreenParams: (appId: string | number) => `/thank/${appId}`,
  customerVerifyParams: (appId: string | number) => `/cvsi/${appId}`,
  customerDocumentsParams: (appId: string | number) => `/customer-documents/${appId}`,
  customerRequestDocumentsParams: (appId: string | number) => `/customer-request-documents/${appId}`,
  healthCheckReady: `/health-check/ready`,
  healthCheckAlive: `/health-check/alive`,
  page500: '/500',
  page404: '/404',
};

export const AppTitles: AppTitleProp = {
  thankScreen: 'Thank',
  confirmOTP: 'Confirm OTP',
  customerVerify: 'Customer Verify',
  customerDocuments: 'Customer Documents',
  customerRequestDocuments: 'Customer Request Documents',
  healthCheckReady: `Health Ready`,
  healthCheckAlive: `Health Alive`,
};
