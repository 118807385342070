export const AuthEndpoint = {
  logout: '/api/accounts/logout',
  retrieveMyInformation: '/api/agents',
  getPhone: (id: string) => `api/verification/phone-number/${id}`,
  resendOtp: '/api/verification/resend-otp',
  resendOtpV2: '/api/verification/resend-otp-v2',
  sendOtp: '/api/verification/send-otp/v2',
  confirmOtp: '/api/verification/confirm-otp/v2',
  documentCredential: (params: any) =>
    `/api/document/credential/v2?applicationId=${params.applicationId}&password=${params.password}`,
  getDocumentCustomerRequestList: (appId: string) => `/api/document/list-file/${appId}`,
  getDocumentCustomerRequest: (id: string) => `/api/document/detail/${id}`,

  checkLink: (params: any) => `/api/communication/validate-link?applicationId=${params?.appId}&type=${params?.type}`,
  checkHealthReady: '/api/health/ready',
  checkHealthLive: '/api/health/alive',
};
