import { Layout } from 'antd';
import { memo, ReactNode } from 'react';
import { useCurrentUser } from 'hooks/useCurrentUser';
import HeaderComponent from '../Main/Header';
import styles from './index.module.scss';

const { Content, Header } = Layout;

type Props = {
  children: ReactNode;
  isMenu?: boolean;
};

const ClientLayout = memo<Props>(({ children, isMenu }) => {
  const user = useCurrentUser();

  return (
    <Layout>
      <Header
        className="tw-sticky"
        style={{
          top: 0,
          zIndex: 1,
          padding: 0,
          height: 'inherit',
          backgroundColor: '#f5f3f6',
        }}
      >
        <HeaderComponent isMenu={isMenu} />
      </Header>
      <Layout className="tw-bg-input tw-bg-white">
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
});

export default ClientLayout;
