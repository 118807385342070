import axios from 'axios';
import { join } from 'lodash';
import qs from 'qs';

export const request = axios.create({
  // Timeout 30p
  baseURL: process.env.REACT_APP_API_URL || 'https://customers.scbclportal.fwd.co.th',
  timeout: 30 * 60 * 1000,
  withCredentials: true,
});

const checkIsDefaultRequest = (config: any) => {
  if (!config) return true;
  return false;
};

request.interceptors.request.use(
  function (config) {
    if (checkIsDefaultRequest(config)) return config;

    if (config.method === 'post' || config.method === 'patch' || config.method === 'put')
      config = {
        ...config,
        ...{
          data: qs.stringify(config.data),
        },
      };

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

const mySession = window.sessionStorage;

export const generateSession = () => {
  const cryptoWindow = window.crypto;
  const array = new Uint32Array(2);
  const crId = '_' + join(cryptoWindow?.getRandomValues(array), '');
  mySession.setItem('sessionId', crId);
  return crId;
};
request.interceptors.request.use(
  (config: any) => {
    const sessionId = mySession.getItem('sessionId') || generateSession();
    Object.assign(config.headers, { apikey: (window as any)._env_?.REACT_APP_RAHADPAN_API });
    if (sessionId) {
      Object.assign(config.headers, { 'session-id': sessionId });
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export function getCookie(name: string) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = (document.cookie as string).split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}
