import React from 'react';
import { Button, ButtonProps } from 'antd';
import classNames from 'classnames';
import { memo } from 'react';
import styles from './index.module.scss';

interface Props extends ButtonProps {}

export const AppButton: React.FC<Props> = memo(({ ...props }) => {
  return <Button {...props} className={classNames(props.className, styles.appButton)} />;
});

export default AppButton;

AppButton.defaultProps = {};
