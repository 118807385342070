import React, { createContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

export const LocationContext = createContext<any>('');

export function LocationContextProvider({ children }: any) {
  const lastVisitedLocation: any = React.useRef(null);
  function registerLocation(location: any) {
    lastVisitedLocation.current = location;
  }
  return (
    <LocationContext.Provider value={{ registerLocation, lastVisitedLocation }}>{children}</LocationContext.Provider>
  );
}

export function Debugger() {
  const lastesLocaltion = useLocation(); // only to rerender this component
  const { lastVisitedLocation } = React.useContext(LocationContext);
  //console.log('lastVisitedLocation: ', { lastVisitedLocation });
  //console.log('store location:: ', locsd);
  window.sessionStorage.setItem('latestLocation', lastesLocaltion.pathname.toString());
  return null;
}

export const MySpecialLink = React.forwardRef((props: any, ref) => {
  //console.log('propsprops:: ', props);
  //console.log('refref:: ', ref);
  const { registerLocation } = React.useContext(LocationContext);

  return <Link onClick={() => registerLocation(props.to)} ref={ref} {...props} />;
});
