import './App.css';
import { RootRouter } from './routes';
import { ProgressLoading } from 'components/ProgressLoading';
import { createContext, Suspense, useEffect } from 'react';
import { useMeQuery } from './services/queries/useMeQuery';
import Spinner from './components/Spinner';
import { NetworkStatusCode, RetrieveMyInformationResponse, ThrowMessageEmun } from './services/types';
import { UserEntity } from 'services/types/user';
import { GlobalDebug } from 'helpers/remove-consoles';
import { LocationContextProvider } from 'hooks';
import { Debugger } from 'hooks/locationContextProvider';
import { decryptResponseData } from 'helpers/common';

export const UserContext = createContext<RetrieveMyInformationResponse | undefined>(undefined);
function App() {
  decryptResponseData({ data: (window as any)._env_.REACT_APP_DATA })
    .then((decryptedData) => {
      (window as any)._env_ = { ...(window as any)._env_, ...JSON.parse(JSON.parse(decryptedData)) };
      return;
    })
    .catch((error) => {
      console.error('Error decrypting data:', error);
    });

  // /**
  //  * @REMOVE_CONSOLES
  //  * // remove the working of console logs
  //  * // remove any accidental use of console logs
  //  */
  useEffect(() => {
    const envConfig = (window as any)._env_ ?? process.env;
    const environment = envConfig.NODE_ENV;
    (environment === 'production' || environment === 'uat') && GlobalDebug(false, false);
  }, []);

  const { isLoading, data } = useMeQuery({
    onError: (err: any) => {
      if (
        err?.response?.status === NetworkStatusCode.NOT_FOUND &&
        err?.response?.data?.message === ThrowMessageEmun.INVALID_SESSION
      ) {
        window.sessionStorage.setItem('sessionId', '');
      }
    },
  });

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Suspense fallback={<ProgressLoading />}>
      <UserContext.Provider value={data as UserEntity}>
        <LocationContextProvider>
          <RootRouter />
          <Debugger />
        </LocationContextProvider>
      </UserContext.Provider>
    </Suspense>
  );
}

export default App;
