import i18n from 'i18next';
import thaiLang from './locales/th';
import enLang from './locales/en';
import { initReactI18next } from 'react-i18next';

enum LangPropsEnum {
  'THA' = 'tha',
  'ENG' = 'en',
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    defaultNS: 'label',
    lng: LangPropsEnum.THA,
    resources: {
      tha: thaiLang,
      en: enLang,
    },
    fallbackLng: LangPropsEnum.THA,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    initImmediate: false,
  });

export default i18n;
