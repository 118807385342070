import moment from 'moment';
import { DocumentTypeName, ProductType } from 'services/types';
import { dateFormat } from './constants';
import { Buffer } from 'buffer';

export const formatDate = (date: any, format: string) => {
  return date?.toString().trim() ? moment(date, dateFormat).format(format) : '';
};

export const formatNumber = (number: number) => {
  return number.toFixed(0).replace(/./g, function (c, i, a) {
    return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
  });
};

function hasDecimal(num: number) {
  return !!(num % 1);
}

export const formatCurrency = (n: number, currency?: string) => {
  return (
    n.toFixed(hasDecimal(n) ? 2 : 0).replace(/./g, function (c: any, i: any, a: any) {
      return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
    }) +
    ' ' +
    currency
  );
};

export const getCommaSeparatedTwoDecimalsNumber = (number: any) => {
  const fixedNumber = Number.parseFloat(number).toFixed(2);
  return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getEnumKeyByEnumValue = (myEnum: any, enumValue: number | string): string => {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
  return keys.length > 0 ? keys[0] : '';
};

export const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export function saveIMG(image: string) {
  const startIndex = image.indexOf('base64,') + 7;
  const b64 = image.substr(startIndex);
  const byteCharacters = atob(b64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const data = new Blob([byteArray], { type: 'image/jpeg' });
  return data;
}

export const getBrowser = () => {
  if (window.navigator.userAgent.indexOf('CriOS') !== -1) {
    return 'Chrome';
  } else {
    return 'Safari';
  }
};

export const downloadBase64File = (contentType: string, base64Data: string, fileName: string) => {
  const popup = document.createElement('a');
  const blob = b64toBlob(base64Data, getBrowser() === 'Safari' ? 'application/octet-stream' : 'application/pdf');
  const url = window.URL.createObjectURL(blob);
  popup.href = url;
  popup.target = '_blank';
  popup.download = fileName?.endsWith('.pdf') ? decodeURIComponent(fileName) : `${decodeURIComponent(fileName)}.pdf`;
  document.body.appendChild(popup);
  popup.click();
};

export const adjustFileName = (t: any, item: any) => {
  const prefix = item?.fileName;
  if (!prefix) return prefix;

  const p_type: string = prefix.split('_')[0];
  const p_id: string = prefix.split('_')[1];
  switch (item?.documentType) {
    case DocumentTypeName.SALES_ILLUSTRATION:
      return p_type === ProductType.HEALTH
        ? `${t('label:si_health_name')}-${p_id}.pdf`
        : `${t('label:si_cl_name')}-${p_id}.pdf`;
    case DocumentTypeName.APPLICATION_FORM:
      return p_type === ProductType.HEALTH
        ? `${t('label:appform_health_name')}-${p_id}.pdf`
        : `${t('label:appform_cl_name')}-${p_id}.pdf`;
  }
};

const aesDecryption = async (encryptedData: any, iv: any, authTag: any, key: any, salt: any) => {
  const algorithm = {
    name: 'AES-GCM',
    iv: iv,
    tagLength: 128,
  };

  const keyMaterial = await window.crypto.subtle.importKey(
    'raw',
    new TextEncoder().encode(key),
    { name: 'PBKDF2' },
    false,
    ['deriveKey'],
  );
  const cryptoKey = await window.crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt: new Uint8Array(salt),
      iterations: 2145,
      hash: 'SHA-512',
    },
    keyMaterial,
    { name: 'AES-GCM', length: 256 },
    false,
    ['decrypt'],
  );
  const encryptedArray = encryptedData;
  const authTagArray = authTag;
  const combinedData = new Uint8Array(encryptedArray.length + authTagArray.length);
  combinedData.set(encryptedArray);
  combinedData.set(authTagArray, encryptedArray.length);
  const decrypted = await window.crypto.subtle.decrypt(algorithm, cryptoKey, combinedData);
  return new TextDecoder().decode(decrypted);
};

export const decryptResponseData = async (encrypted: any): Promise<any> => {
  try {
    const secretKey = process.env.REACT_APP_CODE;
    console.log('secretKey:: ', secretKey);
    const binData = Buffer.from(encrypted.data, 'base64');
    const salt = binData.slice(0, 64);
    const iv = binData.slice(64, 80);
    const tag = binData.slice(80, 96);
    const text = binData.slice(96);
    return await aesDecryption(text, iv, tag, secretKey, salt);
  } catch (error) {
    throw new Error('decryption error.');
  }
};
