import { Fragment } from 'react';
import Page from 'pages';
import { AppRoutes, AppTitles } from 'helpers';
import { Redirect, Switch } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute';
import { PrivateRouteProps } from 'components/PrivateRoute';
import ClientLayout from 'layouts/Client';
import MainLayout from 'layouts/Main';

const exact: boolean = true;
const isPrivate: boolean = true;
// const isAuthRoute: boolean = true;

export const routes: PrivateRouteProps[] = [
  {
    path: AppRoutes.customerDocuments,
    exact,
    title: AppTitles.customerDocuments,
    component: Page.customerDocuments,
    isAuthRoute: false,
    layout: ClientLayout,
    isPrivate: false,
    preventRedirect: true,
  },
  {
    path: AppRoutes.confirmOtp,
    exact,
    title: AppTitles.confirmOTP,
    component: Page.confirmOTP,
    isAuthRoute: false,
    layout: ClientLayout,
    isPrivate: false,
  },
  {
    path: AppRoutes.customerVerify,
    exact,
    title: AppTitles.customerVerify,
    component: Page.customerVerify,
    isAuthRoute: false,
    layout: ClientLayout,
    isPrivate: false,
  },
  {
    path: AppRoutes.thankScreenClient,
    exact,
    title: AppTitles.thankScreen,
    component: Page.thankClient,
    isAuthRoute: false,
    layout: ClientLayout,
    isPrivate: false,
  },

  {
    path: AppRoutes.healthCheckReady,
    exact,
    title: AppTitles.healthCheckReady,
    component: Page.healthCheckReady,
    layout: MainLayout,
    isPrivate: false,
  },
  {
    path: AppRoutes.healthCheckAlive,
    exact,
    title: AppTitles.healthCheckAlive,
    component: Page.healthCheckAlive,
    layout: MainLayout,
    isPrivate: false,
  },

  { path: AppRoutes.page500, isPrivate: false, component: Page.Page500 },
  { path: AppRoutes.page404, isPrivate: false, component: Page.Page404, layout: ClientLayout },
];

export const RootRouter = () => {
  return (
    <Fragment>
      <Switch>
        {routes.map((route) => (
          <PrivateRoute key={route.path?.toString()} {...route} />
        ))}
        <Redirect from="/" to={AppRoutes.page404} />
      </Switch>
    </Fragment>
  );
};
