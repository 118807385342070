import React, { memo, useEffect, useState } from 'react';
import { NetworkStatusCode, ThrowMessageEmun } from 'services/types';
import PopErrorConfirm from 'components/popup/ConfirmPop';
import { AppRoutes } from 'helpers';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import useLogOutMutation from 'services/mutations/useLogoutMutation';

interface Props {
  err?: any;
  navigate?: string;
}
export const UseHookError: React.FC<Props> = memo(({ err, navigate }) => {
  const { t } = useTranslation();
  const [messageError, setMessageError] = useState<{ title?: any; content: any }>({
    title: t(`label:GENERIC_ERROR`),
    content: '',
  });
  const [error, setError] = useState<any>(null);
  const isSaveProceed = err?.isSaveProceed;
  const client = useQueryClient();
  const history = useHistory();

  let timer1: NodeJS.Timeout | null = null;
  useEffect(() => {
    return () => {
      if (timer1) clearTimeout(timer1);
    };
  }, [timer1]);

  const { mutate: logout, isLoading } = useLogOutMutation({
    onError: (err) => {
      //console.log('errrrrrrr', err);
    },
  });

  const handleLogout = () => {
    // if (history?.location?.pathname == AppRoutes.login) return;

    logout();
    // timer1 = setTimeout(() => {
    //   client.clear();
    //   localStorage.clear();
    //   sessionStorage.removeItem('sessionId');
    //   window.sessionStorage.setItem('sessionId', '');
    //   window.sessionStorage.setItem('latestLocation', '');
    //   history.push(AppRoutes.login);
    // }, 300);
  };

  useEffect(() => {
    if (
      err?.response?.status === NetworkStatusCode.NOT_FOUND &&
      err?.response?.data?.message == ThrowMessageEmun.INVALID_SESSION
    ) {
      window.sessionStorage.setItem('sessionId', '');
      setMessageError({
        title: t(`label:SESSION_EXPIRED`),
        content: (
          <div>
            {t(`home:err_session_content_a`)} <br /> {t(`home:err_session_content_b`)}
          </div>
        ),
      });
    } else {
      const msg = ThrowMessageEmun[err?.response?.data?.message] && t(`label:${err?.response?.data?.message}`);

      const _msg = err?.response?.data?.message;

      if (_msg == ThrowMessageEmun.LICENSE_NOT_FOUND || _msg == ThrowMessageEmun.LICENSE_EXPIRED) {
        setError(_msg);
        const errmsg = {
          ...messageError,
          title: <div style={{ textAlign: 'center' }}>{t(`label:${_msg}`)}</div>,
          content: (
            <div
              style={{
                fontWeight: 400,
                fontSize: 13,
                width: '100%',
                textAlign: 'center',
                marginLeft: 0,
                marginTop: 8,
                fontFamily: 'Noto Sans Thai',
              }}
            >
              {t(`label:${_msg}_DETAIL`) + ' '}
              <span style={{ color: '#E87722', fontWeight: 'bold', fontFamily: 'Noto Sans' }}>
                {t(`label:CONTACT_DETAIL`)}
              </span>
            </div>
          ),
        };
        setMessageError(errmsg);
      } else if (_msg == ThrowMessageEmun.IMAGE_CENSOR_ERROR) {
        setError(_msg);
        const errmsg = {
          ...messageError,
          title: <div style={{ textAlign: 'center' }}>{t(`label:IMAGE_CENSOR_TITLE_ERROR`)}</div>,
          content: (
            <div
              style={{
                fontWeight: 400,
                fontSize: 13,
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingLeft: 50,
                paddingRight: 50,
              }}
            >
              {t(`label:IMAGE_CENSOR_TITLE_MESSAGE_A`)} <br /> {t(`label:IMAGE_CENSOR_TITLE_MESSAGE_B`)}
            </div>
          ),
        };
        setMessageError(errmsg);
      } else {
        if (
          ThrowMessageEmun[err?.response?.data?.message] === 'PRODUCT_NOT_AVAILABLE_FOR_SALE' ||
          ThrowMessageEmun[err?.response?.data?.message] === 'CONCURRENT_LOGIN_ERROR' ||
          ThrowMessageEmun[err?.response?.data?.message] === 'APP_ALREADY_SUBMITTED'
        ) {
          const errmsg = {
            ...messageError,
            title: t(`label:GENERIC_ERROR`),
            content: t(_msg),
          };
          setMessageError(errmsg);
        } else if (ThrowMessageEmun[err?.response?.data?.message] === 'INVALID_REQUEST_ACCESS') {
          window.location.href = AppRoutes.page404;
        } else if (err?.response?.status === NetworkStatusCode.GATEWAY_TIMEOUT) {
          const errmsg = {
            ...messageError,
            title: t(`label:GENERIC_ERROR`),
            content: <div dangerouslySetInnerHTML={{ __html: t(`label:GATEWAY_TIMEOUT_ERROR`) }} />,
          };
          setError(_msg);
          setMessageError(errmsg);
        } else {
          const errmsg = {
            ...messageError,
            title: msg || (isSaveProceed ? t(`label:SAVE_PROCEED_ERROR`) : t(`label:GENERIC_ERROR`)),
            content: msg
              ? t(`label:PLEASE_TRY_AGAIN`)
              : isSaveProceed
              ? t(`label:PLEASE_TRY_AGAIN`)
              : (err?.response?.data?.message && t(`label:GENERIC_ERROR_DETAIL`)) || err?.message || '',
          };

          setMessageError(errmsg);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [err]);

  return (
    <>
      <PopErrorConfirm
        visible={Boolean(messageError.content)}
        title={messageError.title || ''}
        onOk={() => {
          if (error == ThrowMessageEmun.APP_ALREADY_SUBMITTED) {
            history.push(AppRoutes.thankScreenClient);
          }
          if (
            error == ThrowMessageEmun.LICENSE_NOT_FOUND ||
            error == ThrowMessageEmun.LICENSE_EXPIRED ||
            ThrowMessageEmun[err?.response?.data?.message] === 'CONCURRENT_LOGIN_ERROR'
          ) {
            setMessageError({ title: 'ขออภัยในความไม่สะดวก', content: '' });
            handleLogout();
            setError(null);
          } else {
            setMessageError({ title: 'ขออภัยในความไม่สะดวก', content: '' });
            timer1 = setTimeout(() => {
              if (messageError.title === 'เซสชันหมดเวลา') window.location.href = AppRoutes.page500;
              else {
                if (navigate) window.location.href = navigate;
              }
            }, 200);
          }
        }}
        message={messageError.content}
        showButton
        maskClosable={false}
      ></PopErrorConfirm>
    </>
  );
});
