import { Layout } from 'antd';
import { memo, ReactNode, useEffect, useState } from 'react';
import { useCurrentUser } from 'hooks/useCurrentUser';
import HeaderComponent from './Header';
import ConfirmModal from 'components/popup/ConfirmPop';
import { useTranslation } from 'react-i18next';
import { useHistory, matchPath, useParams } from 'react-router-dom';
import { AppRoutes } from 'helpers';
import { useQueryClient } from 'react-query';
import { addEventListeners, removeEventListeners } from '../../helpers/eventListenerUtil';
import { UseHookError } from 'hooks/useHookError';
import LOGIN_BG from 'assets/images/login_bg.png';
import styles from './index.module.scss';
import cls from 'classnames';
import Countdown, { CountdownRenderProps } from 'react-countdown';

const { Header, Content, Sider } = Layout;

type Props = {
  children: ReactNode;
  isMenu?: boolean;
};

const MainLayout = memo<Props>(({ children, isMenu }) => {
  const [isVisibleNearTimeout, setIsVisibleNearTimeout] = useState<boolean>(false);
  const [isVisibleTimeout, setIsVisibleTimeout] = useState<boolean>(false);
  const user = useCurrentUser();
  const { t } = useTranslation();
  const history = useHistory();
  const client = useQueryClient();
  const [onErrors, setOnErrors] = useState<any>({});
  const [collapsed, setCollapsed] = useState(true);

  const pathname = history?.location?.pathname;
  const params: any = useParams();

  const sessionId = window.sessionStorage.getItem('sessionId');

  const [backButton, setBackButton] = useState<any>(null);

  useEffect(() => {
    if (!sessionId) return;
    const createTimeout1 = () => {
      console.log('createNearTimeout start');
      return setTimeout(() => {
        setIsVisibleNearTimeout(true);
      }, 1000 * 60 * 13);
    };

    const createTimeout2 = () => {
      console.log('createTimeout start');
      return setTimeout(() => {}, 100);
    };

    const listener = () => {
      if (!isVisibleNearTimeout) {
        console.log('clear');
        clearTimeout(timeout);
        timeout = createTimeout1();
      }
    };

    // Initialization
    let timeout = isVisibleNearTimeout ? createTimeout2() : createTimeout1();
    addEventListeners(listener);

    // Cleanup
    return () => {
      console.log('clear');
      removeEventListeners(listener);
      clearTimeout(timeout);
    };
  }, [isVisibleNearTimeout, sessionId]);

  const checkRenderHeaderDefault = () => {
    return true;
  };

  const rendererTimer = ({ total, completed }: CountdownRenderProps) => {
    if (completed) {
      return <span>0</span>;
    } else {
      // Render a countdown
      return <span>{total / 1000}</span>;
    }
  };

  const getBgColorHeader = () => {
    if (history.location.pathname == '/login') return '#170102';
    if (!checkRenderHeaderDefault()) return 'white';

    return '#f5f3f6';
  };

  const _cls = history.location.pathname == '/login' ? styles.tabletLogin : '';
  const _classPadding = 'tw-pt-0 tw-pl-0 tw-pr-0 md:tw-pt-10 md:tw-pl-6 md:tw-pr-10';
  const _classDefault = 'tw-mt-0 tw-ml-0 tw-mr-0';

  return (
    <Layout className="tw-bg-input">
      <Header
        className={cls('md:tw-sticky', { 'tw-sticky': checkRenderHeaderDefault() == true }, styles.headerMobile)}
        style={{
          top: 0,
          zIndex: 1,
          padding: 0,
          height: 'inherit',
          backgroundColor: getBgColorHeader(),
        }}
      >
        <HeaderComponent
          // isLoginProp={Boolean(user?.isOTPPass)}
          isMenu={isMenu}
          isHaveChild={checkRenderHeaderDefault() != true}
        >
          {/* <BreadcrumbComponent></BreadcrumbComponent> */}
        </HeaderComponent>
      </Header>

      <Layout className={cls(_cls)}>
        {checkRenderHeaderDefault() != true && (
          <Sider
            className={styles.mainSider}
            trigger={null}
            width={242}
            breakpoint="md"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              setCollapsed(broken);
              //console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              setCollapsed(collapsed);
              //console.log(collapsed, type);
            }}
            style={{
              overflow: 'hidden',
              height: 'calc(100vh - 98px)',
              position: 'sticky',
              top: 98,
              left: 0,
            }}
          >
            {/* <SideMenuComponent></SideMenuComponent> */}
          </Sider>
        )}

        <Layout
        // style={{ backgroundColor: '#f5f3f6' }}
        // className="site-layout" style={{ marginLeft: collapsed ? 0 : 242 }}
        >
          {/* <div className={cls(styles.wrapper, checkRenderHeaderDefault() ? styles.headerDefault : '')}> */}
          <Content
            style={{
              minHeight: 'calc(100vh - 98px)',
              // paddingTop: checkRenderHeaderDefault() ? 0 : 65,
              // paddingLeft: checkRenderHeaderDefault() ? 0 : 39,
              // paddingRight: checkRenderHeaderDefault() ? 0 : 72,
            }}
            className={checkRenderHeaderDefault() ? _classDefault : _classPadding}
          >
            {children}
          </Content>
          {/* </div> */}
        </Layout>

        <ConfirmModal
          closable={false}
          showButton={true}
          showCancelButton={true}
          manualClosable={false}
          visible={isVisibleNearTimeout}
          destroyOnClose={true}
          title={t('home:session_near_timeout')}
          okTextProp={t('label:stayLoggedIn')}
          cancelTextProp={t('label:logOut')}
          message={
            <div style={{ fontSize: '13px', lineHeight: '20px' }}>
              {t('home:session_near_timeout_a')} <br />
              {t('home:session_near_timeout_b')}{' '}
              <Countdown
                date={Date.now() + 120000}
                intervalDelay={0}
                precision={0}
                renderer={rendererTimer}
                onComplete={() => {
                  console.log('time out');
                  client.clear();
                  localStorage.clear();
                  sessionStorage.removeItem('sessionId');
                  window.sessionStorage.setItem('latestLocation', '');
                  setIsVisibleNearTimeout(false);
                  setIsVisibleTimeout(true);
                }}
              />{' '}
              {t('home:second_suffix')}
            </div>
          }
          onOk={() => {
            setIsVisibleNearTimeout(false);
            // refetch();
          }}
          onCancel={() => {
            setIsVisibleNearTimeout(false);
            client.clear();
            localStorage.clear();
            sessionStorage.removeItem('sessionId');
            window.sessionStorage.setItem('latestLocation', '');
            // history.push(AppRoutes.login);
          }}
        />
        <ConfirmModal
          closable={false}
          showButton={true}
          visible={isVisibleTimeout}
          manualClosable={false}
          destroyOnClose={true}
          title={t('home:err_session_title')}
          message={
            <div style={{ fontSize: '13px', lineHeight: '20px' }}>
              {t('home:err_session_new_a')} <br />
              {t('home:err_session_new_b')}
            </div>
          }
          onOk={() => {
            setIsVisibleTimeout(false);
            client.clear();
            localStorage.clear();
            sessionStorage.removeItem('sessionId');
            window.sessionStorage.setItem('latestLocation', '');
            // history.push(AppRoutes.login);
          }}
        />
        <UseHookError err={onErrors} />
      </Layout>
    </Layout>
  );
});

export default MainLayout;
