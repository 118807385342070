import React, { Component, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

type State = {
  hasError: boolean;
  error?: Error | any;
  errorMessage?: string | any;
};

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
    errorMessage: '',
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMessage: error?.message, error };
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Something went wrong.</h1>
          <div>
            <h2>{this.state.errorMessage}</h2>
            <br />
            <h3> {this.state.error && this.state.error.toString()}</h3>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
