import { memo } from 'react';
import styles from './index.module.scss';
import cls from 'classnames';
interface Props {
  isLoginProp?: boolean;
  isMenu?: boolean;
  isHaveChild?: boolean;
}
const Header: React.FC<Props> = memo(({ isLoginProp, isMenu, isHaveChild, children }) => {
  return (
    <div className="tw-flex md:tw-z-10 md:tw-relative">
      <header
        className={cls(
          styles.tabletHeader,
          'tw-relative tw-bg-white tw-shadow-default tw-flex tw-pl-4 tw-flex-col tw-w-full',
        )}
      >
        <div
          className={cls(styles.tabletHeaderContent, 'tw-flex tw-justify-between tw-items-center tw-h-56px tw-flex-1')}
        >
          <img className={isHaveChild ? cls(styles.FWDImg, styles.haveBorder) : styles.FWDImg} alt="FWD" />
        </div>

        {isHaveChild && <div className={cls(styles.childrenWraper, 'tw-mb-4')}>{children}</div>}
      </header>
    </div>
  );
});

export default Header;
