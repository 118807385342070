import { UserEntity } from './user';
import { AxiosError } from 'axios';
import { ProductEntity } from './product';
export type Maybe<T> = T | null | undefined;

export type APIErrorResponse = AxiosError<ErrorResponse>;

export type RoleEnum = 'SELLER' | 'CUSTOMER';

export enum Channel {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
}

export enum OTPTYPES {
  SMS_OTP = 'SMS_OTP',
  EMAIL_OTP = 'EMAIL_OTP',
  SMS_LINK_OTP = 'SMS_LINK_OTP',
  EMAIL_LINL_OTP = 'EMAIL_LINL_OTP',
}

export enum AccountStatusEnum {
  RESET_REQUESTED = 'RESET_REQUESTED',
  ACTIVATED = 'ACTIVATED',
  NOT_ACTIVATED = 'NOT_ACTIVATED',
  DELETED = 'DELETED',
}

export enum NetworkStatusCode {
  SUCCESS = 200,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  FORBIDDEN = 403,
  GATEWAY_TIMEOUT = 504,
  SERVER_ERROR = 500,
}

export enum ThrowMessageEmun {
  RESEND_OTP_ERROR = 'RESEND_OTP_ERROR',
  SEND_OTP_NOT_FOUND_CONTACT = 'SEND_OTP_NOT_FOUND_CONTACT',
  SEND_OTP_NOT_FOUND_APPLICATION_NUMBER = 'SEND_OTP_NOT_FOUND_APPLICATION_NUMBER',
  SEND_SI_ERROR = 'SEND_SI_ERROR',
  GET_CUSTOMER_PROFILE_ERROR = 'GET_CUSTOMER_PROFILE_ERROR',
  UPDATE_CUSTOMER_PROFILE_ERROR = 'UPDATE_CUSTOMER_PROFILE_ERROR',
  UPDATE_CUSTOMER_PROFILE_NOT_FOUND = 'UPDATE_CUSTOMER_PROFILE_NOT_FOUND',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  SESSION_EXPIRED_DETAIL = 'SESSION_EXPIRED_DETAIL',
  PLEASE_TRY_AGAIN = 'PLEASE_TRY_AGAIN',
  UPDATE_APPLICATION_ERROR = 'UPDATE_APPLICATION_ERROR',
  DELETE_APPLICATION_ERROR = 'DELETE_APPLICATION_ERROR',
  UNABLE_TO_SEND_OTP_LINK = 'UNABLE_TO_SEND_OTP_LINK',
  DATA_SUBMISSION_NOT_COMPLETED = 'DATA_SUBMISSION_NOT_COMPLETED',
  LICENSE_NOT_FOUND = 'LICENSE_NOT_FOUND',
  LICENSE_EXPIRED = 'LICENSE_EXPIRED',
  PRODUCT_NOT_AVAILABLE_FOR_SALE = 'PRODUCT_NOT_AVAILABLE_FOR_SALE',
  INVALID_REQUEST_ACCESS = 'INVALID_REQUEST_ACCESS',
  CONCURRENT_LOGIN_ERROR = 'CONCURRENT_LOGIN_ERROR',
  IMAGE_CENSOR_ERROR = 'IMAGE_CENSOR_ERROR',
  INVALID_SESSION = 'INVALID_SESSION',
  APP_ALREADY_SUBMITTED = 'APP_ALREADY_SUBMITTED',
}

export interface LoginInput {
  username: string;
  password: string;
  sessionId: string;
}

export interface ForgotPasswordInput {
  username: string;
}

export interface LoginResponse {
  // accessToken: string;
  response: {
    message: any;
    status: number;
    user: UserEntity;
    casMessage: string;
  };
}

export interface ForgotPasswordResponse {
  message: string;
  status: number;
  casMessage: string;
}

export interface ErrorResponse {
  result?: boolean;
  message: any;
  code?: any;
  request?: any;
  response?: any;
  isAxiosError: boolean;
}

export interface RetrieveMyInformationResponse {
  id: number;
  idCard: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
  licenseNo: string;
  agentCode: string;
  branchCode: string;
  branchName: string;
  licenseStartDate: string;
  licenseExpireDate: string;
  staffId: string;
}

export enum TypeOfBorrowerEnum {
  BORROWER = '001',
  CO_BORROWER = '002',
  GUARANTOR = '003',
}

export enum TypeOfLoanEnum {
  HomeLoan = 'HOME',
  AutoLoan = 'AUTO',
}

export enum TypeGender {
  MALE = 'M',
  FEMALE = 'F',
}

export enum TypeWeightChange {
  INCREASE = 'INC',
  DECREASE = 'DEC',
}

export enum TypeResidence {
  THAI = 'TH',
  FOREIGNER = 'FOR',
}

export enum DocumentTypeID {
  IDCARD = '13004',
  PASSPORT = '13001',
}

export enum DocumentTypeName {
  EMAIL_ATTACHMENTS = 'EMAIL_ATTACHMENTS',
  APPLICATION_FORM = 'APPLICATION_FORM',
  SALES_ILLUSTRATION = 'SALES_ILLUSTRATION',
  IDENTITY_DOCUMENT = 'IDENTITY_DOCUMENT',
}

export enum ProductType {
  MAIN_HOME = 'home',
  MAIN_AUTO = 'auto',
  HEALTH = 'health',
}

export enum AdditionalDocumentType {
  ADDITIONAL_IDENTITY = 'ADDITIONAL_IDENTITY',
  ADDITIONAL_BUDDHIST_MONK = 'ADDITIONAL_BUDDHIST_MONK',
  ADDITIONAL_OTHER = 'ADDITIONAL_OTHER',
}

export interface ApplicationInput {
  borrowerType: TypeOfBorrowerEnum;
  appType: TypeOfLoanEnum;
}

export interface BeneficairyInput {
  id: string;
  applicationId: string;
  beneficiaries: Beneficiaries[];
}

export interface LoanDetailInput {
  id: string;
  applicationId: string;
  loanAccountNumber: string;
  loanPeriod: number;
  loanAmount: number;
  debtAmount: number;
  loanStartDate: string;
  branchName: string;
  branchCode: string;
  channel: string;
}

export interface ApplicationResponse {
  redirectUrl: string;
  token: string;
}

export interface CustomerInfoResponse {
  id: string;
  appId: string;
  applicationType: TypeOfLoanEnum;
  borrowerType: TypeOfBorrowerEnum;
  firstname: string;
  lastname: string;
  gender: string;
  dob: string;
  age: string;
  totalPremium: string;
  calculationDate: string;
  calculationLastEffectiveDate: string;
  product: ProductEntity[];
  deletedAt: any;
  createdAt: any;
  updatedAt: any;
  phone: string;
  email: string;
  coBorrowerCondition: string;
  status: ApplicationStatusEnumType;
}

export interface CustomerProfilePutInput {
  firstname: string;
  lastname: string;
  appId: string;
  email?: string;
  phone: string;
}

export type SendTypeEnum = 'NONE' | 'SMS' | 'EMAIL' | 'BOTH';
export interface CustomerSummaryPutInput {
  scbAppNumber: string;
  sendType: SendTypeEnum;
  appId: string;
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  sendFlag: string;
}

export interface ApplicationDetailInput {
  id: string;
}

export interface ApplicationDetailResponse {
  id: string;
  scbApplicationNo: string;
  applicationNo: string;
  applicationType: string;
  status: string;
  borrowerType: string;
  insureId: string;
  customerPhone: string;
  customerEmail: string;
  contactId: string;
  phoneComplete: string;
  insuranceSummaryComplete: string;
  beneficiaryId: string;
  healthQuestionId: string;
  healthApplicationNo: string;
  fatcaId: string;
  consentId: string;
  loanId: string;
  sumInsuredFlag: OPCIndicatorEnum;
  pdpaId: string;
  insureDocumentComplete: string;
}

export interface InsureProfilePutInput {
  id?: string;
  applicationId: string;
  title: string;
  firstName: string;
  oldFirstName: string;
  lastName: string;
  oldLastName: string;
  gender: string;
  birthdate: string;
  height: number | undefined;
  weight: number | undefined;
  weightChange6month?: string;
  weightChange?: number;
  weightChangeReason?: string;
  maritalStatus: string;
  nationality: string;
  documentType: string;
  documentId?: string;
  idcardNo: string;
  idcardExpiryDate: string;
  occupationCode: string;
  position: string;
  jobDescription: string;
  annualIncome?: number;
  businessType: string;
  revenueFlag?: string;
  foreignerTaxId?: string;
  widowedReason?: string;
  widowedDate?: string;
}

export interface ApplicationItem {
  id: string;
  scbApplicationNo: string;
  applicationNo: string;
  applicationType: string;
  borrowerType: string;
  totalPremium: number;
  applicationSignedDate: string;
  clWithHealthInsurance: string;
  opcIndicator: string;
  revenueFlag: string;
  foreignerTaxId: string;
  dataSource: string;
  policyPackDeliveryMethod: string;
  profileName: string;
  insureName: string;
  status: string;
  createdBy: string;
  updatedBy: string;
  deletedBy: string;
  deletedAt: Date;
  createdAt: Date;
  updatedAt: Date;
  submissionAt: any;
}

export interface ApplicationItems {
  applicationType: TypeOfLoanEnum;
  data: any;
  date: Date;
  scbApplicationNo: string;
}

export interface MetaEntity {
  currentPage: number;
  itemCount: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number;
}

export type ApplicationListInput = {
  limit: string | number;
  page: string | number;
  status: string;
};

export interface ApplicationListResponse {
  items: ApplicationItems[];
  links: any;
  meta: MetaEntity;
}

export type ApplicationStatusEnumType = '001' | '002' | '003' | '004';

export enum ApplicationStatusEnum {
  DRAFT = '001',
  WAITING_OTP_CONFIRM = '002',
  OTP_CONFIRMED = '003',
  SUBMITTED = '004',
}
export interface InsureProfileResponse {
  id: string;
  applicationId: string;
  title: string;
  firstName: string;
  oldFirstName: string;
  lastName: string;
  oldLastName: string;
  gender: string;
  birthdate: string;
  height: number;
  weight: number;
  weightChange6month: string;
  weightChange: number;
  weightChangeReason: string;
  maritalStatus: string;
  nationality: string;
  documentType: string;
  documentId: string;
  idcardNo: string;
  idcardExpiryDate: string;
  occupationCode: string;
  position: string;
  jobDescription: string;
  annualIncome: number;
  businessType: string;
  revenueFlag: string;
  foreignerTaxId: string;
  widowedReason: string;
  widowedDate: string;
  maritalStatusText: string;
  nationalityText: string;
  occupationText: string;
  contact: Contact;
}

export interface Contact {
  currentAddressDistrict: string;
  currentAddressNo: string;
  currentAddressPostCode: string;
  currentAddressProvince: string;
  currentAddressSoi: string;
  currentAddressStreet: string;
  currentAddressSubdistrict: string;
  currentAddressVillageName: string;
  currentAddressVillageNo: string;
}

export interface PersonalInfoForm {
  title?: string;
  firstName: string;
  lastName: string;
  dobDay: string;
  dobMonth: string;
  dobYear: string;
  gender: string;
  maritalStatus?: string;
  height?: number;
  weight?: number;
  weightChange6month?: TypeWeightChange | string;
  weightChange?: number;
  weightChangeReason?: string;
  nationality?: string;
  isForeigner?: boolean;
  idCard?: string;
  idcardDay?: string;
  idcardMonth?: string;
  idcardYear?: string;
  occupation?: string;
  occupationPosition?: string;
  occupationResponsibility?: string;
  businesstype?: string;
  annualIncome?: number;
  widowedReason?: string;
  widowedDate?: string;
  changedWeight?: boolean;
  oldFirstName?: string;
  oldLastName?: string;
}

export interface MasterDataResponse {
  nationality?: any;
  occupation?: any;
  maritalStatus?: any;
  title?: any;
}
export interface Beneficiaries {
  name: string;
  age: number;
  relationship: string;
  order: number;
  relationshipText: string;
}
export interface BeneficiariesResponse {
  id: string;
  applicationId: string;
  beneficiaries: Beneficiaries[];
  createdBy: string;
  updatedBy: string;
  deletedBy: string;
  deletedAt: string;
  createdAt: string;
  updatedAt: string;
}

export interface LoanDetailResponse {
  id: string;
  applicationId: string;
  loanAccountNumber: string;
  loanPeriod: number;
  loanAmount: number;
  debtAmount: number;
  loanStartDate: string;
  createdBy: string;
  updatedBy: string;
  deletedBy: string;
  deletedAt: string;
  createdAt: string;
  updatedAt: string;
  branchName: string;
  branchCode: string;
  channel: string;
}

export interface PhoneResponse {
  phone: string;
  ref: string;
}

export interface HealthQuestionResponse {
  id: string;
  applicationId: string;
  insuredInsuranceConcernFlag: string;
  insuredInsuranceConcernDetails: string;
  insuredCiDiagnosisFlag: string;
  insuredCiDiagnosisDetails: string;
  insuredDiabilityMentalDisorderFlag: string;
  insuredDiabilityMentalDisorderDetails: string;
  insuredFiveYearTreatmentFlag: string;
  insuredFiveYearTreatmentDetails: string;
  insuredFamilyDiseaseFlag: string;
  insuredFamilyDiseaseDetails: string;
  insuredDrinkingFlag: string;
  insuredDrinkingDetails: string;
  insuredSmokingFlag: string;
  yearSmoked: number;
  frequencySmoking: number;
  insuredSmokingDetails: string;
}

export interface HealthQuestionParams {
  id?: string;
  applicationId: string;
  insuredInsuranceConcernFlag: string;
  insuredInsuranceConcernDetails: string;
  insuredCiDiagnosisFlag: string;
  insuredCiDiagnosisDetails: string;
  insuredDiabilityMentalDisorderFlag: string;
  insuredDiabilityMentalDisorderDetails: string;
  insuredFiveYearTreatmentFlag: string;
  insuredFiveYearTreatmentDetails: string;
  insuredFamilyDiseaseFlag: string;
  insuredFamilyDiseaseDetails: string;
  insuredDrinkingFlag: string;
  insuredDrinkingDetails: string;
  insuredSmokingFlag: string;
  yearSmoked: number;
  frequencySmoking: number;
  insuredSmokingDetails: string;
}

export interface RevenueTaxURLParams {
  revenueFlag?: string;
  isForeigner: boolean;
  idcardNo?: string;
  foreignerTaxId?: string;
}
export interface RevenueTaxQueryParams {
  id?: string;
  applicationId?: string;
  revenueFlag: string;
  foreignerTaxId?: string;
}

export interface FATCAResponse {
  id?: string;
  applicationId: string;
  notUsCitizenFlag: string;
  createdBy?: string;
  updatedBy?: string;
  deletedBy?: string;
  deletedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface FATCAParams {
  id?: string;
  applicationId: string;
  notUsCitizenFlag: string;
}

export interface ConsentParams {
  id?: string;
  applicationId: string;
  step: number;
}

export interface ClarificationParams {
  applicationId: string;
}

export enum ChoiceAnswer {
  YES = 'Y',
  NO = 'N',
}

export type OPCIndicatorEnum = 'DT' | 'LT';

export type InsuranceSummaryEnum = 'Y' | 'N';
export type ApplicationInsuranceInfoInput = {
  appId: string;
  coBorrowerCondition: InsuranceSummaryEnum;
};

export interface SaveAddressInput {
  id: string;
  applicationId: string;
  registerAddressNo: string;
  registerAddressVillageName: string;
  registerAddressVillageNo: string;
  registerAddressSoi: string;
  registerAddressStreet: string;
  registeredAddressSubdistrict: string;
  registeredAddressDistrict: string;
  registeredAddressProvince: string;
  registeredAddressPostalCode: string;
  registeredAddressFlag: string;
  workPlaceAddressFlag: string;
  currentAddressVillageName: string;
  currentAddressNo: string;
  currentAddressVillageNo: string;
  currentAddressSoi: string;
  currentAddressStreet: string;
  currentAddressSubdistrict: string;
  currentAddressDistrict: string;
  currentAddressProvince: string;
  currentAddressPostCode: string;
  workPlaceCompanyName: string;
  workPlaceAddressNo: string;
  workPlaceVillageNo: string;
  workPlaceSoi: string;
  workPlaceStreet: string;
  workPlaceDistrict: string;
  workPlaceSubdistrict: string;
  workPlaceProvince: string;
  workPlacePostalCode: string;
  contactAddressType: string;
}

export interface SaveContactInput {
  id: string;
  applicationId: string;
  homePhone: string;
  companyPhone: string;
  mobilePhone: string;
  email: string;
  receivePolicyMethod: string;
}
export interface DocumentResponse {
  content: string;
  mimeType: string;
  name: string;
  size: string;
}

export interface DocumentData {
  applicationId: string;
  base64: string;
  fileName: string;
  id: string;
  mimeType: string;
  size: string;
}

export interface Relationship {
  id: number;
  active: true;
  type: string;
  code: string;
  name: string;
  displayOrder: number;
  link: string[];
}

export interface SendNoticeInput {
  applicationId: string;
  email?: string;
  phone?: string;
  sendType?: string;
  firstName?: string;
  lastName?: string;
  resendFlag?: string;
}

export interface OtpInput {
  applicationId: string;
  phone?: string;
  code?: string;
  ref?: string;
  channel: Channel;
}

export interface ConfirmOtpInput {
  applicationId: string;
  phone?: string;
  email?: string;
  code?: string;
  ref?: string;
  channel?: Channel;
}

export interface DocumentCredentialInput {
  applicationId: string;
  password: string;
}

export interface ItemsOfDocumentResponse {
  id: string;
  applicationId: string;
  documentType: string;
  password: string;
  fileName: string;
  base64: string;
}
export interface PdpaInput {
  id: string;
  applicationId: string;
  coreFlag: string;
  dataAnalyticFlag: string;
  crossSellFlag: string;
  disclosurePartnerFlag: string;
}

export interface ConsentTemplate {
  header: ItemTemplate;
  messageList: ItemTemplate[];
  purposeList: PurposeItem[];
  versionCd: string;
}

export interface ItemTemplate {
  th: string;
  en: string;
}

export interface PurposeItem {
  desc: string;
  key: string;
}

export interface DocumentCustomerRequestList {
  id: string;
  applicationId: string;
  documentType: string;
  password: string;
  fileName: string;
}

export interface DocumentCustomerRequestDetail {
  name: string;
  mimeType: string;
  size: string;
  content: string;
}
