import { lazy } from 'react';

const LazyLayout = (importStatement: () => Promise<any>) => {
  const Component = lazy(importStatement);
  return <Component />;
};

export default {
  Page500: lazy(() => import('./../components/Error/500')),
  Page404: lazy(() => import('../components/Error/404')),

  confirmOTP: () => LazyLayout(() => import('./ConfirmOTP/confirm-OTP')),
  thankClient: () => LazyLayout(() => import('./ThankClient/thank-client')),
  customerVerify: () => LazyLayout(() => import('./CustomerVerify/customer-verify')),
  customerDocuments: () => LazyLayout(() => import('./CustomerDocuments/customer-documents')),
  healthCheckReady: () => LazyLayout(() => import('./HealthCheck/health-ready')),
  healthCheckAlive: () => LazyLayout(() => import('./HealthCheck/health-alive')),
};
