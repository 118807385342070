import { ComponentType, FC, Fragment } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, useLocation } from 'react-router-dom';
import { AppRoutes } from 'helpers';
import { Helmet } from 'react-helmet';
import useAuthenticated from 'hooks/useAuthenticated';
import { useQueryParams } from 'hooks/useQueryParams';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { indexOf } from 'lodash';

export type PrivateRouteProps = {
  layout?: ComponentType<any>;
  isPrivate?: boolean;
  isAuthRoute?: boolean;
  title?: string;
  roles?: string[];
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
  preventRedirect?: boolean;
} & RouteProps;

const RouteLayout: FC<PrivateRouteProps> = ({
  layout: Layout,
  component: Component,
  isPrivate = false,
  title,
  path,
  isAuthRoute = false,
  roles,
  preventRedirect,
  ...props
}) => {
  const getDefaultSrc = () => {
    const envConfig = (window as any)._env_ ?? process.env;
    const environment = envConfig.NODE_ENV;
    if (['local'].includes(environment)) {
      return `default-src 'self' ${process.env.REACT_APP_API_URL} 'unsafe-inline'; frame-src 'none'; img-src 'self' data:;`;
    }

    return `default-src 'self' https://*.fwd.co.th 'unsafe-inline'; frame-src 'none'; img-src 'self' data:;`;
  };

  const RenderComponent = (ownProps: any) => {
    return <Component {...ownProps} />;
  };

  return (
    <Route
      {...props}
      path={path}
      render={(ownProps) => {
        return (
          <Fragment>
            <Helmet>
              <meta httpEquiv="Content-Security-Policy" content={getDefaultSrc()} />
              <title>{title ? title : ''}</title>
            </Helmet>
            {Layout ? <Layout>{RenderComponent(ownProps)}</Layout> : RenderComponent(ownProps)}
          </Fragment>
        );
      }}
    />
  );
};

export default RouteLayout;
