import { useMutation, UseMutationOptions } from 'react-query';
import { AuthEndpoint } from './../endpoints';
import { APIErrorResponse } from './../types';
import { request } from 'helpers/request';

export type LoginMutationOptions = UseMutationOptions<APIErrorResponse>;

export default function useLogoutMutation(options?: LoginMutationOptions) {
  return useMutation<APIErrorResponse>(
    (values) => request.post(AuthEndpoint.logout, values).then((res) => res.data),
    options,
  );
}
