import flatten from 'flat';
import label from './label.json';
import button from './button.json';
import home from './home.json';
import dashboard from './dashboard.json';
import errorMessage from './error.json';
import forgotPassErrorMessage from './forgot-password-err.json';
import customer from './customer.json';
import appication from './application.json';

export default {
  button: flatten<Record<string, any>, typeof label>(button, {
    delimiter: '_',
  }),
  home: flatten<Record<string, any>, typeof label>(home, {
    delimiter: '_',
  }),
  label: flatten<Record<string, any>, typeof label>(label, {
    delimiter: '_',
  }),
  dashboard: flatten<Record<string, any>, typeof label>(dashboard, {
    delimiter: '_',
  }),
  errorMessage: flatten<Record<string, any>, typeof label>(errorMessage, {
    delimiter: '_',
  }),
  forgotPassErrorMessage: flatten<Record<string, any>, typeof label>(forgotPassErrorMessage, {
    delimiter: '_',
  }),
  application: flatten<Record<string, any>, typeof label>(appication, {
    delimiter: '_',
  }),
  customer: flatten<Record<string, any>, typeof label>(customer, {
    delimiter: '_',
  }),
};
